"use client"
// import { FirstFooterSectionStoryblok } from '@/app/types/component-types-sb'
import React from 'react'

// type Props = {
//   content: FirstFooterSectionStoryblok
// }

const FirstFooterSection = () => {
  return (
    <div/>
  )
}

export default FirstFooterSection